// CSS for breadcrumbs component in blog app
.breadcrumbs-container {
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1rem;
}
.breadcrumbs-path {
  position: relative;
  margin: 0;
}
.breadcrumbs-arrow {
  height: 1rem;
}
.breadcrumbs-dropdown {
  position: absolute;
  top: -0.5rem;
  left: -0.5rem;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 6rem;
  gap: 0.2rem;
  background-color: white;
  border-radius: 0.25rem;
  padding: 0.5rem;
  box-shadow: 2px 2px 5px #1b1b1b1b, -2px -2px 5px #1b1b1b1b;
}
.breadcrumbs-dropdown-hidden {
  display: none;
}
.breadcrumbs-dropdown-visible {
  display: flex;
  z-index: 100;
}
.breadcrumbs-dropdown-option {
  min-height: 1rem;
  cursor: pointer !important;
  width: 100%;
}
.breadcrumbs-dropdown-option:hover {
  background-color: #f1f1f1;
}

.breadcrumbs-select-button {
  user-select: none;
  position: relative;
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  align-items: center !important;
  margin: 0;
  gap: 0.5rem;
}
.breadcrumbs-arrow-container {
  display: flex;
  justify-content: center;
  opacity: 30%;
  align-items: center;
}
.breadcrumbs-arrow-container-rotated {
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(90deg);
  z-index: 150;
  opacity: 30%;
  cursor: pointer;
}
.bc-rotated-arrow {
  transform: rotate(-90deg);
}