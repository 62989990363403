@import url("https://cdn.jsdelivr.net/gh/orestbida/cookieconsent@v2.8.9/dist/cookieconsent.css");

//----------------------------------------------------/
// The block number above each block
//----------------------------------------------------/
.block-number {
  $color: #191919;

  text-align: center;
  font-weight: bold;
  font-size: 0.8125em;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: $color;
  //opacity: 0.8;
  transition: 0.3s ease-out;

  &:hover {
    opacity: 1;
  }

  &::after {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 6px;
    margin-bottom: 2px;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 3px solid $color;
  }

  a:hover,
  a:focus {
    color: $color;
  }
}


//----------------------------------------------------/
// Make some change to components in demo
//----------------------------------------------------/
.demo-section .main-content .section,
.demo-topbar .main-content .topbar,
.demo-navbar .main-content .navbar,
.demo-header .main-content .header,
.demo-footer .main-content .footer {
  margin: 30px 20px 100px;
  border: 1px solid #f5f6f7;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
}

.demo-topbar .main-content .topbar,
.demo-navbar .main-content .navbar {
  position: static;
  margin: 0;
}


//----------------------------------------------------/
// Index cover
//----------------------------------------------------/
.sample-blocks {

  img {
    border-radius: 0.25rem;

    &.aos-animate {
      opacity: 0.5 !important;
    }

    &:hover {
      opacity: 1 !important;
      transition: 0.7s !important;
    }
  }

  a {
    position: absolute;
  }

  a:nth-child(1) {
    animation: wobble 4s infinite;
    right: 15px;
    bottom: -20px;
  }

  a:nth-child(2) {
    animation: wobble 8s infinite;
    right: 320px;
    bottom: 30px;
  }

  a:nth-child(3) {
    animation: wobble 5s infinite;
    right: 0px;
    bottom: 190px;
  }

  a:nth-child(4) {
    animation: wobble 6s infinite;
    right: 280px;
    bottom: 180px;
  }

  a:nth-child(5) {
    animation: wobble 9s infinite;
    right: 270px;
    bottom: 320px;
  }

  a:nth-child(6) {
    animation: wobble 7s infinite;
    right: 20px;
    bottom: 370px;
  }

  a:nth-child(7) {
    animation: wobble 5s infinite;
    right: 200px;
    bottom: 470px;
  }

  a:nth-child(8) {
    animation: wobble 8s infinite;
    right: 10px;
    bottom: 470px;
  }

  a:nth-child(9) {
    animation: wobble 9s infinite;
    right: 0px;
    bottom: 570px;
  }

}

@keyframes wobble {
  0% {
    transform: rotate(-1deg) translate(-5px, -5px);
  }

  50% {
    transform: rotate(1deg) translate(5px, 5px);
  }

  100% {
    transform: rotate(-1deg) translate(-5px, -5px);
  }
}


//----------------------------------------------------/
// Footer responsive
//----------------------------------------------------/
@media (max-width: 400px) {
  body>.footer {

    .social a:last-child,
    .nav a:last-child {
      display: none;
    }
  }
}

@media(max-width:1112px) {
  .height-screen {
    height: auto;
  }

  .small-screen-hidden {
    display: none;
  }
}

@media(max-width: 767px) {
  .map-hidden {
    display: none;
  }
}

@media(min-width: 1113px) {
  .height-screen {
    height: 465px;
  }
}

.backdrop-navbar {
  z-index: 1000 !important;
}

.progress-container {
  width: 100%;
  height: 8px;
  background: #ccc;
  position: fixed;
  top: 0;
  z-index: 5 !important;
  /* background-color: #f1f1f1; */
}

.progress-bar {
  height: 8px;
  background: #27629e;
  width: 0%;
}

.alert-light hr {
  border-top-color: #214f81 !important;
}

@media(min-width:993px) {
  .blog-new-line {
    display: none;
  }
}

@media(min-width: 768px) {
  .margin-md {
    margin-left: 10%;
  }
}

@media(min-width: 1024px) {
  .margin-lg {
    margin-left: 10%;
  }
}

.h-fullscreen,
.h-100vh {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh !important;
}

@media(min-width: 1113px) {
  .margin-xl {
    margin-left: 10%;
  }
}

.price-unit-down {
  display: inline-block;
  font-size: 16px;
  vertical-align: middle;
  margin-right: -10px;
  margin-top: 16px;
}


@media(max-width: 768px) {
  .card-img-top-blog {
    object-fit: cover;
    height: 300px;
  }
}

@media(min-width: 769px) {
  .card-img-top-blog {
    object-fit: cover;
    height: 250px;
  }
}

@media(max-width: 767px) {
  .card-img-top-blog {
    object-fit: cover;
    height: 250px;
  }
}

@media(max-width:1024px) and (min-width:769px) {
  .ipad-navbar {
    display: inline-block;
    height: 50px !important;
  }
}

.col-xs-4 {
  width: 33.3333%;
}

@media(min-width: 451px) {
  .customer-div {
    margin: auto !important;
    padding: .5rem !important;
  }

  #row-3>.customer-div {
    margin: 10px !important;
    padding: .5rem !important;
  }

  #row-3 {
    justify-content: center;
  }

  .customer-logo {
    width: 75%;
    // height: 50%;
  }

  .gallant-logo {
    width: 100% !important;
  }

  .integration-div {
    max-width: 10% !important;
    margin: auto !important;
  }
}

@media(max-width: 450px) {
  .customer-div {
    width: 50%;
    margin: auto !important;
    padding: 1rem !important;
  }

  .customer-logo {
    width: 140px;
    // height: 50%;
  }

  .integration-div {
    max-width: 28% !important;
    margin: auto !important;
  }

  .w-200 {
    width: 180px !important;
  }

  .btn-group {
    display: flex;
  }

  .df-subtext {
    font-size: 15px;
  }

  .feature-box {
    border-radius: 60px;
    padding: 24px !important;
    margin-top: 45px !important;
    margin-left: 16px;
    margin-right: 16px;
  }
}

// @media (min-width:769px) {
//   .centeralign {
//     align-self:center;
//     display: inline-block;
//     width: 140px !important;
//   }
// }
// @media (min-width:1281px) and (max-width:1440px) {
//   .centeralign {
//     align-self:center;
//     display: inline-block;
//     width: 200px !important;
//   }
// }
// @media (max-width:768px) {
//   .centeralign {
//     align-self:center;
//     display: inline-block;
//     width: 150px !important;
//   }
// }
// @media (max-width:767px) {
//   .centeralign {
//     align-self:center;
//     display: inline-block;
//     width: 120px !important;
//   }
// }
// @media (min-width:1441px) {
//   .centeralign {
//     align-self:center;
//     display: inline-block;
//     width: 200px !important;
//   }
// }

@media(min-width:1024px) and (max-width:1025px) {
  .large-screen-display {
    padding-bottom: 1em;
  }
}


//----------------------------------------------------/
// Blog responsive
//----------------------------------------------------/
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

.blog-lead {
  font-size: 1.125em;
  font-family: "roboto", sans-serif;
  line-height: 1.58;
  color: #676d76;
  font-weight: 400;
}

.author {
  color: rgb(6, 175, 175) !important;
  font-weight: 500 !important;
}

.meta-author {
  font-size: 1em;
  display: inline;
  margin-right: 30px;
}

.meta-date {
  font-size: 0.875em;
  display: inline;
  margin-right: 30px;
}

.meta-comment {
  font-size: 1em;
  display: inline;
  margin-right: 30px;
  color: #757575;
}

.entry-title {
  font-size: 1.625em;
  line-height: 1.15;
}

.comment-icon {
  margin-right: 5px;
}

@media only screen and (max-width:768px) {
  .entry {
    padding-top: 40px;
  }
}

@media only screen and (min-width:768px) {
  .entry-title {
    font-size: 2.5em;
    line-height: 1.15;
  }
}

.auto-height {
  margin: auto;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: space-around;
  align-items: center;
}



@media only screen and (max-width: 1199px) {
  .new-navbar-icon {
    font-size: 20px !important;
  }

  .navbar-card-description {
    display: none;
  }
}

@media only screen and (min-width: 1200px) {
  .new-nav {
    width: 150% !important;
    left: -215px !important;
  }

  .new-navbar-card {
    padding-top: 25px !important;
  }

  .new-navbar-card:hover {
    background-color: #f7f7f7 !important;
  }

  .new-navbar-icon {
    font-size: 20px !important;
  }

  .new-navbar-div {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .dealflow-logo {
    padding-left: 0px !important;
  }

  .navbar-card-description {
    font-size: 12px !important;
  }
}

@media only screen and (min-width: 1367px) {
  .dealflow-logo {
    padding-left: 10px !important;
  }
}

@media only screen and (max-width: 991px) {

  //only from xs to md devices
  .order-xs-last {
    -webkit-box-ordinal-group: 14;
    order: 13;
  }

  .order-xs-first {
    -webkit-box-ordinal-group: 0;
    order: -1;
  }
}

@media only screen and (max-width: 1199px) {
  .solutions-second-div {
    margin-top: 30px;
  }
}

.solutions-header {
  text-transform: uppercase;
  letter-spacing: 1px;
  min-height: 20px;
}

.solutions-link {
  font-size: 14px !important;
}

@media only screen and (min-width: 1200px) {
  .solutions-nav {
    width: 100% !important;
    min-width: 100% !important;
    background-color: rgb(252, 252, 252);
  }

  .solutions-header {
    // min-height: 50px;
    padding: 0.25rem 1.125rem;
  }

  .solutions-link {
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 5px;
  }

  .solutions-link:hover {
    background-color: #f9f9f9;
  }
}

@media only screen and (max-width: 1199px) {
  .pricing-navbar-image {
    display: none;
  }
}

.navbar-link-text {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #757575cc !important;
  cursor: pointer !important;
  text-transform: none !important;
}

.navbar-link-text:hover {
  // color: #205081 !important;
  color: #191919 !important;
}

.navbar-link-text-light {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: white !important;
  cursor: pointer !important;
  text-transform: none !important;
}

.navbar-link-text-light:hover {
  // color: #205081 !important;
  // color: #191919 !important;
}

@media (max-width: 1199px) {
  .navbar-contact-text {
    padding-left: 1.125rem;
  }

  .navbar-login-text {
    padding-left: 1.125rem;
  }

  .btn-light {
    color: #191919 !important;
    border-color: #191919 !important;
  }

  .navbar-link-text-light {
    color: #757575cc !important;
  }

  .navbar-signup-text {
    margin-top: 3rem;
    margin-left: 1.125rem;
  }

  .bundled-pricing-text {
    padding-left: 1.125rem;
  }
}

@media (min-width: 1200px) {
  .navbar-contact-text {
    padding-left: 0px;
    padding-right: 10px;
  }

  .navbar-login-text {
    padding-left: 10px;
    padding-right: 5px;
  }

  .navbar-signup-text {
    margin-left: 5px;
  }
}

@media screen and (min-width: 1200px),
screen and (max-width: 992px) {
  .thankyou-alignment {
    display: none;
  }
}

//wider container
@media (min-width: 1200px) {

  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    // max-width: 1200px !important;
    width: calc(100% - 128px);
    max-width: 1312px;
  }
}

@media (min-width: 1200px) {
  .short-quote {
    min-height: 70px !important;
  }
}


// table on solutions
.investor-table {
  border: solid 1px #aaa;
  border-collapse: collapse;
  border-spacing: 0;
  font: normal 16px Arial, sans-serif;
  text-align: center;
}

.investor-table thead th {
  background-color: #205081;
  color: #fff;
  padding: 15px 10px;
  font-family: 'Poppins', 'Open Sans', sans-serif;
}

.investor-table tbody td {
  border: solid 1px #aaa;
  color: #ffffff;
  padding: 15px 10px;
}

.investor-table .bold-cells td {
  font-weight: 600;
}

.spv-table {
  border: solid 1px #aaa;
  border-collapse: collapse;
  border-spacing: 0;
  font: normal 16px Arial, sans-serif;
  text-align: center;
  margin-bottom: 30px;

}

.spv-table tbody td {
  border: solid 1px #aaa;
  color: #1d1d1d;
  padding: 15px 10px;
}

.spv-table thead th {
  background-color: #205081;
  color: #fff;
  padding: 15px 10px;
  font-family: 'Poppins', 'Open Sans', sans-serif;
}

.spv-table .bold-cells td {
  font-weight: 600;
}


@media (max-width: 426px) {
  .investor-table thead th {
    padding: 5px;
  }

  .investor-table tbody td {
    padding: 5px;
  }

  .feature-card-text {
    font-size: 1.3rem;
    font-weight: 500;
    padding-top: 10px;
    padding-bottom: 0px !important;
    color: #050505;
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 1199px) {
  .navbar-scroll {
    overflow: scroll;
  }

  .resources-icon {
    display: none;
  }
}

.resources-icon {
  width: 25px;
}

.at-share-dock.atss {
  z-index: 10 !important;
}

.breadcrumb-item+.breadcrumb-item::before {
  margin: 0.75rem 0.75rem;
}

.breadcrumb-item-link {
  color: #757575 !important;
}

html {
  scroll-behavior: smooth;
}




#cm {
  #c-inr {
    max-width: 100% !important;
    display: flex !important;
    align-items: center;
    margin: 0px !important;
    justify-content: space-between !important;
  }

  #c-inr-i {
    width: calc(100% - 370px) !important;
    margin: 0px !important;
    padding: 0px !important;
  }

  #c-bns {
    width: 330px !important;
    margin-top: 0px !important;
    flex-direction: row-reverse !important;
  }

  #c-bns button:first-child,
  #s-bns button:first-child {
    margin: 0px 0 0 10px !important;
  }

  #c-bns button:last-child,
  #s-bns button:last-child {
    margin: 0px 0 0 0 !important;
  }

  @media (max-width: 800px) {
    #c-inr-i {
      width: 100% !important;
    }

    #c-inr {
      flex-direction: column !important;
    }

    #c-bns {
      margin-top: 20px !important;
      flex-direction: column-reverse !important;
    }

    #c-bns button:first-child,
    #s-bns button:first-child {
      margin: 10px 0 0 0 !important;
    }

    #c-bns button:last-child,
    #s-bns button:last-child {
      margin: 0px 0 0 0 !important;
    }
  }

  @media (max-width: 350px) {
    #c-bns {
      width: 100% !important;
    }
  }
}

#cm.bar {
  padding: 30px 40px !important;
}

@media (max-width: 800px) {
  #cm.bar {
    padding: 20px !important;
  }
}

.text-link {
  cursor: pointer !important;
}

@import "../../../blog-app/src/components/breadcrumbs.scss";

#error-modal {
  z-index: 9999999;
  display: none;
  position: unset;
  width: 100%;
  height: 100%;
}

.error-modal-box {
  position: absolute;
  width: 500px;
  max-width: 90%;
  height: auto;
  padding-bottom: 60px;
  top: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  background: #fff;
  border-radius: 3px;
  box-shadow: 4px 8px 12px 0 rgba(200, 88, 88, 0.894);
  text-align: center;
  overflow: hidden;
}

#calendly-modal{
  z-index: 10000000000;
  width: 100%;
  position: fixed;
  top:0;
  left:0;
}

.calendly-modal-box {
  position: fixed;
  height: auto;
  padding: 2rem;
  top: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  text-align: center;
  // overflow: hidden;
}

@media (max-width: 425px) {
  .calendly-modal-box {
    padding: 10px;
    max-width: 95%;
  }
}


.details-modal-overlay {
  transition: opacity 0.2s ease-out;
  // pointer-events: none !important;
  background: rgba(#eb8f88, 0.8);
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  width: 100% !important;
  height: 100% !important;
}

.calendly-modal-overlay {
  transition: opacity 0.2s ease-out;
  // pointer-events: none !important;
  background: rgba(#6a6969, 0.9);
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  width: 100vw !important;
  height: 100vh !important;
  // display: none;
}


.calendly-modal-close {
  color:white;
  font-size: 2.5rem;
  font-weight: 500;
  position: absolute;
  right: 25px;
  cursor: pointer;
  z-index: 1;
}

.hide-calendly-modal {
  // display: none !important;
  // visibility: hidden !important;
  opacity: 0 !important;
  z-index: -1 !important;
}

.show-calendly-modal {
  // display: block !important;
  // visibility: visible !important;
  z-index: 10000000000 !important;
  opacity: 1 !important;
}

.hide-error-modal {
  display: none !important;
}

.show-error-modal {
  display: block !important;
}

.error-modal-box svg {
  margin-top: 24px;
}

.error-modal-box .title {
  display: block;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  margin: 14px 0 5px 0;
}

.error-modal-box p {
  font-size: 14px;
  font-weight: 300;
  line-height: 19px;
  margin: 0;
  padding: 10px 30px;
}

.error-modal-box .button {
  position: absolute;
  height: 40px;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--red, #ff4954);
  color: #fff;
  line-height: 40px;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  transition: background .3s ease-in-out;
}

.error-modal-box .button:hover {
  background: #EC3434;
}

.fw-heading {
  font-family: "Poppins", sans-serif;
  font-weight: 700;

}

.fw-heading-light {
  color: white;
}

// add media query for the for fw-heading decrease font size
@media (max-width: 768px) {
  .fw-heading {
    font-size: 2rem;
    line-height: 1;
  }

  .display-2 {
    line-height: 1;
  }
}

.fw-btn {
  padding: 8px 26px 6px;
  letter-spacing: 1.7px;
  border-radius: 0.5rem;
  outline: none;
  -webkit-transition: 0.15s linear;
  transition: 0.15s linear;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
}

.homepage-navbar {
  top: 0px;
}

#homepage-header {
  padding-top: 20vh;
  padding-bottom: 20vh;
  margin-bottom: 0vh;
  background: url(/assets/img/white-wave-bg.svg) center bottom -0.5px / 100% 14% no-repeat scroll padding-box border-box, linear-gradient(to bottom, #004AAD, #5B52B3, #865AB6, #A965B9) 0% 0% / auto repeat scroll padding-box border-box;

  background-blend-mode: normal, normal;
  color: rgb(255, 255, 255);

}

#features {
  margin-top: 5rem;
  background-image: linear-gradient(to bottom, white, #9ba9e040);
  padding-bottom: 5rem;
}

.feature-list {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0rem;
  overflow-x: auto;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
}



.feature-card,
.team-card {
  position: relative;
  margin: 20px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0.7;
  -webkit-transition-duration: .5s;
  transition-duration: .5s;
  width: 100%
}

.feature-card:hover,
.team-card:hover {
  cursor: pointer;
  transform: translateY(-5px);
}

.feature-card-selected:hover,
.team-card-selected:hover {
  cursor: pointer !important;
  transform: scale(1.15) translateY(-5px) !important;
}

.feature-card-heading,
.team-card-heading {
  font-size: 1.2rem;
  font-weight: 600;
  color: rgb(5, 5, 5);
  margin: 0.8rem;
  text-align: center;
}

.feature-card-text {
  // font-family: "Poppins", sans-serif;
  font-size: 1.3rem;
  font-weight: 500;
  padding-bottom: 20px;
  color: #050505;
  // display: flex;
  // justify-content: center;
}

.feature-card-text:hover>a {
  text-decoration: underline;
}

.feature-card-icon,
.team-card-icon {
  margin: 0px 0px 0px 0px;
  padding: 6px;
  font-size: 2rem;
}

.feature-card-icon-selected,
.team-card-icon-selected {
  padding: 10px;
  border-radius: 8px;
}

.feature-card-icon-selected.text-warning {
  background-color: #ffba0033;
}

.feature-card-icon-selected.text-success {
  background-color: #0fa95833;
}

.feature-card-icon-selected.text-danger {
  background-color: #54385433;
}

.feature-card-icon-selected.text-primary {
  background-color: #20508133;
}

.feature-card-selected,
.team-card-selected {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default !important;
  // background-color: white; 
  transform: scale(1.15);
  opacity: 1;
}

.feature-card-selected>h4 {
  margin: 0.3rem;
}


.hero-image {
  width: 100%;
  height: 25rem;
  object-fit: cover;
  object-position: top;
}

.feature-image-border {
  border-radius: 5px;
  border: 0px;
  max-height: 90%;
  // width:100%;
  margin: 0px 20px 20px 20px;
  // box-shadow: 0px 1px 5px #2d2b2b;
}

.feature-image-border>div {
  transition-duration: .5s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.feature-image {
  border-radius: 10px;
  width: 85%;
  margin: 0px;
  margin-bottom: 5rem;
  padding: 0px;
  transition-duration: 0.5s;
  box-shadow: 0 0px 10px #00000029;
}

@media (max-width: 650px) {
  .feature-image {
    width: 100%;
    margin-top: 2rem;
  }
}



#signup-middle {
  padding: 10vh 0;
  background-image: linear-gradient(0.5turn, #004AAD, #5B52B3, #865AB6);
}

#signup-footer {
  background-image: linear-gradient(to top, #1e3c72 0%, #1e3c72 5%, #004aad 100%);
  clip-path: ellipse(75% 100% at center top);
  padding: 5rem 0;
}

.homepage-button {
  color: #004aad;
  text-transform: none;
}

.homepage-button:hover {
  color: #004aad;
  background-color: whitesmoke !important;
  border-color: whitesmoke !important;
}

.fw-header {
  position: relative;
  background-position: center;
  -webkit-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 0vh;
  margin-bottom: 15vh;
}

.feature-header {
  position: relative;
  background-position: center;
  -webkit-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 0vh;
  top: 0px;
}

.hidden {
  display: none;
}

.team-card-detail {
  padding: 30px 0px 30px 50px;
}

.team-card-detail-border {
  border-radius: 10px;
  max-height: 90%;
  width: 100%;
  padding: 1rem;
  -webkit-box-shadow: 0 0px 10px #00000029;
  box-shadow: 0 0px 10px #00000029;
  margin-bottom: 5rem;
}

.team-card-detail-heading {
  font-size: 1.25rem;
  font-weight: 600;
  color: rgb(5, 5, 5);
  margin: 20px;
  font-family: sans-serif;
}

.team-card-detail-text {
  font-size: 1rem;
  font-weight: 500;
  color: rgb(5, 5, 5);
  margin: 20px;
}

.team-card-detail-list {
  margin-left: 24px;
}

.team-card-feature-group :first-child {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.team-card-feature-group :last-child {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.team-card-feature-container {
  padding: 5%;
}

.team-card-feature-button {
  margin-top: 2rem;
}

.team-card-feature-button .btn {
  width: 100%;
}


.team-card-mobile {
  display: none;
}

.team-card-signup-button {
  display: none;
}


@media (max-width: 1023px) {

  .team-card-feature-group,
  .team-list,
  .testimonial-parent,
  .team-card-detail>p:nth-child(3) {
    display: none;
  }

  .team-card-detail-border {
    display: block !important;
    width: 19rem;
    flex-shrink: 0;
    margin: 5rem 1rem;
  }

  .team-card-detail {
    display: block;
    max-width: none;
    padding: 5px;
  }

  .team-card-detail-container {
    display: block;
  }

  .team-cards {
    display: flex;
    overflow-x: auto;
  }

  .team-card-detail-heading {
    margin-left: 20px;
    font-size: 1rem;
  }

  .team-card-mobile {
    display: block;
    margin-left: 15px;
    width: 75%;
    text-align: left !important;
  }

  .team-card-heading {
    margin: 0px;
  }

  .signup-btn-mobile {
    max-width: none;
  }

  .team-card-signup-button {
    display: block;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
  }

  .fa-ul {
    margin-left: 20px !important;
  }

  .feature-image-border {
    margin: 0px;
  }

}

.team-card-detail-feature {
  font-size: 1rem;
  font-weight: 500;
  color: #050505;
  display: flex;
  align-items: center;
  gap: 1rem;
  border: 1px solid #eee;
  padding: 0px 10px;
}

.list-icon {
  padding: 0px;
  font-size: 30px;
  margin-bottom: 0px;
}

.list-icon-df {
  padding: 0px;
  font-size: 20px;
  margin-bottom: 0px;
}

.list-icon-border-top {
  border-top-left-radius: 10px;
  border-top: 1px solid #205081;
  border-left: 1px solid #205081;
  padding: 0px 5px 0px 15px;
}

.list-icon-border-middle {
  border-top: 1px solid #205081;
  border-left: 1px solid #205081;
  padding: 0px 5px 0px 15px;
}

.list-icon-border-bottom {
  border-bottom-left-radius: 10px;
  border-top: 1px solid #205081;
  border-left: 1px solid #205081;
  border-bottom: 1px solid #205081;
  padding: 0px 5px 0px 15px;
}

.customer-logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
}

.customer-testimonial {
  font-size: 0.8rem;
  font-weight: 400;
  color: rgb(5, 5, 5);
  margin: 20px 20px 20px 0px;
}

.signup-input-group {
  border: 1px solid #d6d9de;
  border-radius: 2px;
  transition: all 0.3s ease-out;
  position: relative;
  display: flex;
  -webkit-box-align: stretch;
  align-items: stretch;
  width: 100%;
}

.signup-input-group>input {
  border: 0px;
  max-height: 40px;
  font-size: 0.9rem !important;
}


.signup-input-group:focus-within {
  border-color: #37608B;
  border-width: 0.1rem;
}
// .df-demo-container-big {
//   background-color: blue !important;
// }

//feature pages

.sub-feature-list {
  display: flex;
  // justify-content: space-between;
  margin-bottom: 0rem;
  overflow-x: auto;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  height: 30vh;
}

#portfolio-collect {
  height: 20vh;
}

#investor-allocations {
  height: 20vh;
}

.sub-feature-card {
  position: relative;
  margin: 10px;
  display: flex;
  flex-direction: column;
  opacity: 0.7;
  -webkit-transition-duration: .5s;
  transition-duration: .5s;
  width: 40%;
}

.sub-feature-card-selected:hover {
  cursor: pointer !important;
}

.sub-feature-card-heading-selected {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default !important;
  // background-color: white; 
  opacity: 1;
  color: black !important;
  background-color: #20508133;
  border-radius: 4px;
  padding-left: 5px;
}

.sub-feature-card-heading {
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
}

.sub-feature-card:hover {
  cursor: pointer;
}

.feature-box {
  border-radius: 60px;
  padding: 80px;
  margin-top: 50px;
}

.feature-page-image {
  border-radius: 10px;
  padding: 5px;
  box-shadow: 0 0px 10px #00000029;
  background-color: white;
}

.pink {
  background-color: rgb(255, 248, 251);
}

.blue {
  background-color: rgb(237, 250, 254);
}

.violet {
  background-color: rgb(245, 238, 252);
}

.green {
  background-color: rgb(240, 251, 248);
}

.yellow {
  background-color: rgb(250, 254, 235)
}

.peach {
  background-color: #fce8e8
}

@import "../../dealflow/assets/scss/style";

@import "./scss/fontawesome.scss";

.img-gif-video-container {
  display: flex;
  justify-content: center;
  overflow-x: auto;
  padding: 10px;
  padding-top: 20px;
  padding-bottom: 50px;
  white-space: nowrap;
  gap: 20px;
  background-color: #f0f0f0;

}

@media (max-width: 768px) {
  .img-gif-video-container {
    justify-content: flex-start;
    /* Left align items on smaller screens */
  }
}

.testimonial-box {
  width: 180px;
  height: 320px;
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  flex: 0 0 auto;
}

.testimonial-box-full {
  max-width: 100%;
  height: 560px;
  width: 315px;
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  flex: 0 0 auto;
}

.default-image,
.hover-image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.1s ease-in-out;
  border-radius: 15px;
}

.hover-image {
  opacity: 0;
}

.testimonial-box:hover .hover-image {
  opacity: 1;
}

.testimonial-box:hover .default-image {
  opacity: 0;
}

.testimonial-box-full:hover .hover-image {
  opacity: 1;
}

.testimonial-box-full:hover .default-image {
  opacity: 0;
}

.customer-stories-h3 {
  padding-top: 20px;
  display: flex;
  justify-content: center;
  overflow-x: auto;
  background-color: #f0f0f0;
}

@media only screen and (min-width: 768px) {
  .flex-display {
    display: flex !important;
  }
}

.grayscale-image {
  -webkit-filter: grayscale(100%);
  /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.testimonials-challenge-content {
  width: 60%;
  
}

.testimonials-challenge-content h2 {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
}

.testimonials-challenge-content p {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 20px;
  color: black;
}

.testimonial {
  width: 35%;
  background-color: #f5f7fa;
  padding: 20px;
  border-radius: 8px;
  height: fit-content; 
  width: 100%;
}

.testimonials-author {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.testimonials-author img {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-right: 15px;
}

.testimonials-author p {
  margin: 0;
}

.testimonials-feature {
  background-color:#f5f7fa ;
}



.point {
  display: flex;
  align-items:center;
  justify-content: center;
  gap: 0.5rem;
  max-width: 15rem;
  text-align: left;
}

.point img {
  width: 2rem;
  height: 2rem;
  margin-top: 0.2rem;
}

.point p {
  font-size: 0.875rem;
  line-height: 1.4rem;
  margin: auto;
}

@media (max-width: 768px) {
  .points-container {
    display: none;
  }
}

.signup-customers img{
filter: grayscale(100%) brightness(120%)
}

.signup-customers img:hover {
  filter: grayscale(0%) brightness(100%)
}

.signup-customers{
  // min-width: 50%;
  max-width: 70%;
}

.signup-customers-headline{
  font-family: 'Poppins', sans-serif;
    text-align: center;
    font-size: 1.3em;
    margin: 1em 0 -1em 0;
}

.list-icon-signup{
  font-size: 1.2em;
}

 .green-box {
   background-color: #EFFFEE;
   border-radius: 5px;
   text-align: center;
   display: flex;
   align-items: center;
   justify-content: center;
   gap: 20px;
   width: 100%;
   transform: translateY(-50%);
   padding: 0.5em;
 }

 .green-box img {
   width: 2em;
   height: 2em;
 }

 .green-box p {
   margin: 0;
   color: #007E10;
   font-family: 'Poppins', sans-serif;
   font-size:small;
 }
 .green-box div {
  justify-items: flex-start;
  text-align: left;
 }

 .green-box .bold-text {
   font-weight: bold;
 }


.slide-in-top {
  transform: translateY(-20%);
  opacity: 0;
  transition: all 0.5s ease-out;
}

.slide-in-top.active {
  transform: translateY(0);
  opacity: 1;
}

.fade-in {
  // transform: translateY(-20%);
  opacity: 0;
  transition: all 0.5s ease-out;
}

.fade-in.active {
  // transform: translateY(0);
  opacity: 1;
}

.form-control{
  font-size: 0.9rem !important;
  max-height: 40px;
}

#fund-structure, #number-of-funds{
  color: #999;
}


#submitButton:disabled{
   opacity: 1;
    cursor: not-allowed;
}
// .form-group.d-flex button {
//   flex: 1;
//   margin: 0.25rem;
//   max-width: calc(100% / 4 - 0.5rem);
//   text-align: center;
// }

.form-group.d-flex {
  width: 100%;
  margin: 0 auto;
}

/* Fundraising Buttons */
.fundraising-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.fundraising-buttons .fundraising-btn {
  flex: 1;
  margin: 0.25rem;
  text-align: center;
  width: calc(50% - 0.5rem);
  /* Adjust based on number of buttons */
}

/* Number of Funds Buttons */
.funds-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.funds-buttons .funds-btn {
  flex: 1;
  margin: 0.25rem;
  text-align: center;
  max-width: calc(100% / 4 - 0.5rem);
  /* Adjust based on number of buttons */
}

.funds-btn, .fundraising-btn{
  border: 1px solid #d6d9de;
  color: #999;
}
