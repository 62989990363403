
//----------------------------------------------------/
// Dealflow landing Page
//----------------------------------------------------/
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');


.df-banner-container {
  display: flex !important;
  justify-content: center !important;
  width: 100%;
}
.df-text-center {
  text-align: center;
}
.df-font-huge {
  font-size: 5rem;
}
.df-cta-buttons-container {
  display: flex;
  justify-content: center;
}
.df-uppercase {
  text-transform: uppercase;
}
.df-support-text {
  font-size: 12px;
  letter-spacing: 3px;
  margin: 0;
  font-weight: 500;
}
.df-button {
  border-radius: 0.5rem;
}
.df-btn-primary:hover .df-btn-arrow {
  transition: all 0.2s ease-in-out;
  transform: rotate(0deg);
}
.df-btn-arrow {
  width: 1rem;
  transition: all 0.2s ease-in-out;
  transform: rotate(180deg);
}
.df-btn-primary {
  display: inline-flex;
  justify-content: space-evenly;
}

.df-heading {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
}
.df-subtext {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
}
.df-subtext-bold {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
}
.df-text-gradient {
	background: linear-gradient(to right, #30CFD0 0%, #330867 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.dealflow-feature-card {
  background: linear-gradient(135deg, #fffcf8, #ffdcc0) !important;
  padding-top: 2rem !important;
  border-radius: 0.5rem;
  font-size: 16px;
}
.dealflow-feature-card:hover {
  animation: card-pop 0.25s ease-in-out;
}
.dealflow-feature-card-icon {
  padding-top: 1rem;
  padding-bottom: 0.5rem;
}
@keyframes card-pop {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(.95);
  }
  100% {
    transform: scale(1);
  }
}

.df-pricing-card-radius {
  border-radius: 0.5rem !important;
}
.df-pricing-card {
  transition: all 0.15s ease-in-out;
  transform: translateY(0rem);
}
.df-pricing-card:hover {
  transition: all 0.15s ease-in-out;
  border-radius: 0.5rem !important;
  transform: translateY(-0.25rem);
}
.df-pricing-trial-btn {
  border-radius: 0.5rem !important;
}
.df-btn-rad {
  border-radius: 0.5rem !important;
}
.df-heading {
  margin-bottom: 1rem;
}
.df-section-pricing {
  margin-bottom: 2rem !important;
}
.df-centered {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.df-header {
  // height: 50rem;
  margin-bottom: 10rem;
}
.df-main-content {
  align-self: center;
  width: 69rem;
  display: flex;
  flex-direction: column;
  gap: 6rem;
  margin-bottom: 10rem;
}
.df-section {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
}
.df-feature-title {
  margin-bottom: 1rem;
  width: 100%;
}
.df-feature-title-lh {
  text-align: center;
  line-height: 2rem;
}
.df-demo-container-big {
  width: 100%;
  border-radius: 1rem;
  // border: #e6e6e6 solid 2px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  // padding: 1rem 1.5rem 0rem 1.5rem;
  background-color: #fff;
}


#deal-page{
  border-radius: .5rem !important;
  border: #d6d6d6 solid 1px;
  width: 100%;
  object-fit: contain;
  padding: 2em;
}

.df-demo-big-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 2rem;
  gap: 2rem;
  width: 100%;
}
.df-demo-big-content {
  max-width: 90%;
}
.df-demo-charts-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
  gap: 1rem;
  width: 100%;
  height: 3rem;
}
.df-demo-container-small {
  border-radius: 1rem;
  border: #e6e6e6 solid 2px;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 1rem 1.5rem 0rem 1.5rem;
  background-color: #ffffff;
}
.df-feature-icon {
  height: 1.4rem;
  padding-right: 0.3rem;
}
.df-toggle-button {
  transition: all 0.15s ease-in-out;
  padding: 0rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.2rem;
  height: 2.5rem;
  border-radius: 0.5rem;
  border: #e6e6e6 solid 1px;
  background-color: #ffffff;
}
.df-toggle-button:hover {
  cursor: pointer;
  filter: brightness(0.93);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}
.df-btn-selected {
  filter: brightness(0.93);
  transition: all 0.3s ease-in-out;
  user-select: none;
  cursor: default !important;
}

.df-feature-heading-text {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-size: 1rem; 
  color: $color-dark;
  line-height: 1.4rem;
}
.df-feature-body-text {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  color: $color-dark;
  line-height: 1.3rem;
}
.df-feature-desc {
  width: 100%;
}
.df-feature-demo {
  border: #d6d6d6 solid 1px;
  border-radius: .5rem .5rem 0 0;
  width: 100%;
  object-fit: cover;
}
.df-feature-demo-large {
  border: #d6d6d6 solid 1px;
  border-radius: .5rem .5rem 0 0;
  width: 100%;
  // height: 20rem;
  object-fit: contain;
  border-bottom: #ffffff;
}
#shared-inbox-content > img {
  border-radius: .5rem !important;
  border: #d6d6d6 solid 1px;
  width: 100%;
  object-fit: contain;
}
#team-tasks-content > img {
  border-radius: .5rem !important;
  border: #d6d6d6 solid 1px;
  width: 100%;
  object-fit: contain;
}
#shared-inbox-image {
  padding: 1rem;
  background-color: white;
}
.df-pitch-page-frame {
  height: 40rem;
}
.df-feature-demo-small {
  height: 18rem;
  object-fit: contain;
  border: #d6d6d6 solid 1px;
  border-radius: .5rem .5rem 0 0;
}
.df-features-container-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-top: 1rem;
}
.df-features-container-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  margin-top: 1rem;
}
.df-features-container-3-mixed {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
#df-customise-card {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 3;
}
.df-w-60 {
  width: 60%;
}
.df-mb-2 {
  margin-bottom: 0.5rem;
}
.df-hide {
  opacity: 0;
}
.df-fade-in {
  animation: fade-in-up 1s forwards;
}
@keyframes fade-in-up {
  0% {
    opacity: 0;
    transform: translateY(5rem);
  } 
  100% {
    opacity: 1;
    transform: translateY(0rem);
  }
}
@keyframes fade-in{
  0% {
    opacity: 0;
  } 
  100% {
    opacity: 1;
  }
}
.df-drive-by-section-container {
  display: grid;
  grid-template-columns: 1fr 2fr;
  position: relative;
}
.df-drive-by-text-container {
  margin-top: 4rem;
  margin-bottom: 4rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
}
.df-drive-by-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 40svh;
  width: 100%;
  padding-right: 1rem;
}

.df-drive-by-text>* {
  margin-bottom: 0.5rem;
}

.df-drive-by-text>*:last-child {
  margin-bottom: 0;
  /* Remove margin from the last item */
}
.df-hidden {
  display: none !important;
}
.df-fixed-card {
  width: 49rem;
  border: #e6e6e6 solid 2px;
  background-color: white;
  object-fit: contain;
  top: calc(50vh - 17rem);
  position: sticky;
  border-radius: 0.6rem;
}
.df-cohort-analysis {
  height: 28rem;
  width: 100%;
  padding: 1rem;
}
.df-cohort-analysis > figure > #container {
  height: calc(100% - 3.5rem);
  width: 100%;
}
.df-cohort-analysis > figure > #container3 {
  height: calc(100% - 3.5rem);
  width: 100%;
}
.df-cohort-analysis > figure {
  height: 100%;
  width: 100%;
}
#gmail-extention-image {
  background-color: white;
  border: #e6e6e6 solid 2px;
  padding: 1rem 1.5rem 0rem 1.5rem;
}
.df-mobile-features {
  display: none;
}



@media (max-width: 1200px) {
  .df-main-content {
    align-self: center;
    width: 42rem;
    display: flex;
    flex-direction: column;
    gap: 6rem;
    margin-bottom: 10rem;
  }
  .df-w-60 {
    width: 80%;
  }
  .df-button {
    border-radius: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    min-width: 12rem !important;
  }
  .df-fixedcontainer {
    width: 42rem;
  }
  .df-fixed-card {
    width: 100%;
  }
  #df-feature-image {
    top: calc(50vh - 10.5rem);
    position: sticky;
  }
  .df-cohort-analysis > figure {
    height: 100%;
    width: calc(100% - 0.6rem);
  }
  .df-cohort-analysis > figure > #container {
    height: calc(100% - 3.5rem);
    width: 100%;
  }
  .df-cohort-analysis > figure > #container3 {
    height: calc(100% - 3.5rem);
    width: 100%;
  }
  .df-cohort-analysis{
    height: 20rem;
    width: 100%;
    padding: 0.3rem;
  }
  .df-drive-by-text {
    width: 100%;
    padding-right: 1rem;
  }

.df-drive-by-text>* {
  margin-bottom: 0.5rem;
}

.df-drive-by-text>*:last-child {
  margin-bottom: 0;
  /* Remove margin from the last item */
}
  .df-drive-by-text-container {
    margin-top: 0;
  }
  .df-drive-by-section-container {
    width: 100%;
  }
  .df-demo-container-small {
    border-radius: 1rem;
    border: #e6e6e6 solid 2px;
    display: flex;
    flex-direction: column;
    align-items: center;
  
    padding: 1rem 1rem 0rem 1rem;
    background-color: #ffffff;
  }
  #gmail-extention-image {
    padding: 1rem 1rem 0rem 1rem;
  }
}
@media (max-width: 800px) {
  .df-main-content {
    align-self: center;
    width: 25rem;
    display: flex;
    flex-direction: column;
    gap: 5rem;
    margin-bottom: 10rem;
  }
  .df-fixedcontainer {
    display: none;
  }
  .df-fixed-card {
    display: none;
  }
  .df-drive-by-text {
    width: 100%;
    padding-right: 0;
  }
  .df-demo-container-big {
    border-radius: 1rem;
    // border: #e6e6e6 solid 2px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    background-color: #ffffff;
  }
  .df-demo-container-small {
    border-radius: 1rem;
    border: #e6e6e6 solid 2px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    padding: 1rem 1rem 0 1rem;
    background-color: #ffffff;
  }
  .df-w-60 {
    width: 100%;
  }
  .df-button {
    border-radius: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    min-width: 12rem !important;
  }
  .df-features-container-2 {
    display: flex;
    flex-direction: column;
  }
  .df-features-container-3-mixed {
    display: flex;
    flex-direction: column;
  }
  #df-customise-card {
    height: 80vh;
  }
  .df-drive-by-section-container {
    display: flex;
    flex-direction: column;
  }
  .df-mobile-features {
    display: block;
    width: 100%;
    border: #e6e6e6 solid 2px;
    border-radius: 0.5rem;
  }
  .df-cohort-analysis > figure > #container2 {
    height: calc(100% - 3.5rem);
    width: 100%;
  }
  .df-cohort-analysis > figure > #container4 {
    height: calc(100% - 3.5rem);
    width: 100%;
  }
  #shared-inbox-content > img {
    border-radius: .5rem !important;
    border: #d6d6d6 solid 1px;
    width: 100%;
    object-fit: contain;
    height: 14rem;
  }
  #team-tasks-content > img {
    border-radius: .5rem !important;
    border: #d6d6d6 solid 1px;
    width: 100%;
    object-fit: contain;
    height: 14rem;
  }
}
@media (max-width: 450px) {
.df-demo-big-content {
  max-width: 100%;
}
#shared-inbox-image {
  padding: 0px;
}
}


#faq {
  width: 100%;
  margin: 0 auto;
  text-align: left;
  font-family: 'Poppins', sans-serif;
}

.df-feature-title h2 {
  font-weight: 700;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.faq-container {
  margin-top: 20px;
  width: 100%;
}

.faq-item {
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  margin-bottom: 15px;
  overflow: hidden;
  transition: all 0.3s ease;
}

.faq-item:hover {
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
}

.faq-question {
  background-color: #fff;
  color: #434343;
  padding: 15px 20px;
  width: 100%;
  text-align: left;
  border: none;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.faq-question:hover {
  background-color: #f0f0f0;
}

.faq-icon {
  font-size: 20px;
  transition: transform 0.3s ease;
}

.faq-answer {
  padding: 15px 20px;
  background-color: #f9f9f9;
  display: none;
  font-size: 16px;
  color: #555;
}

.faq-answer ul {
  list-style-type: disc;
  margin-left: 20px;
}

.faq-answer li {
  margin-bottom: 10px;
}
.show-trial{
  display: none;
}

@media (max-width: 768px) {

  .df-drive-by-text {
height: fit-content;
  }
}